import makeRequest from 'network/instance/makeRequest';
import { IResponseError } from 'network/rest/commonTypes';
import { TResponse } from 'network/instance/types';

export interface IGetPresentationDemosRequest {
  id: number;
}

export interface IGetPresentationDemosResponseOk {
  id: number;
  live: boolean;
  created_at: string;
  updated_at: string;
  usersCount: number;
}

export type TGetPresentationDemosResponse =
  | IGetPresentationDemosResponseOk[]
  | IResponseError;

export const getPresentationDemos = (
  params: IGetPresentationDemosRequest
): TResponse<TGetPresentationDemosResponse> => {
  return makeRequest({
    url: `/presentation/${params.id}/demonstrations`,
  });
};
