import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import sortByOrder from 'helpers/sortByOrder';
import { useAppDispatch } from 'hooks/redux';
import {
  IGetSlideResultsAnswers,
  IGetSlideResultsResponseOk,
} from 'network/rest/creatorDemoSession/getSlideResults';
import {
  getCurrentDemoSlideAction,
  getPresentationAction,
  getPresentationDemosAction,
  getSlideResultsAction,
  getSlideVotingStatusAction,
  setDemoSlideAction,
  setSlideVotingStatusAction,
  startDemoAction,
} from './thunk';
import {
  CREATOR_DEMO_SESSION_ALIAS,
  ICreatorDemoSessionState,
  pdfObjI,
  TCurrentSlide,
} from './types';

const initialState: ICreatorDemoSessionState = {
  websocketStatus: { kind: 'noConnection' },
  loadingState: { kind: 'done' },
  currentSlide: { kind: 'none', id: 0 },
  votes: {},
  votingStatus: {},
  demoRunning: { kind: 'none' },
  usersCount: 0,
  visibleVoting: true,
  answers: [],
};

export const creatorDemoSessionSlice = createSlice({
  name: CREATOR_DEMO_SESSION_ALIAS,
  initialState,
  reducers: {
    startConnectionCreator: (state) => {
      state.websocketStatus = { kind: 'connecting' };
    },
    setDemoUsersCount: (state, action: PayloadAction<number>) => {
      state.usersCount = action.payload;
    },
    setVotingVisibility: (state, action: PayloadAction<boolean>) => {
      state.visibleVoting = action.payload;
    },
    setSlideResultsVotes: (
      state,
      action: PayloadAction<IGetSlideResultsResponseOk>
    ) => {
      state.votes[action.payload.slide.id] = {
        respondents: action.payload.respondents,
        results: action.payload.choices,
      };
    },
    setSlideResultsAnswers: (
      state,
      action: PayloadAction<IGetSlideResultsAnswers>
    ) => {
      state.answers[action.payload.slide.id] = {
        results: action.payload.answers,
        respondents: action.payload.respondents,
      };
    },
    setCurrentSlide: (
      state,
      action: PayloadAction<{ slide: TCurrentSlide }>
    ) => {
      state.currentSlide = action.payload.slide;
    },
    setDemoStatus: (state, action: PayloadAction<{ demoRunning: boolean }>) => {
      state.demoRunning = {
        kind: 'some',
        value: action.payload.demoRunning,
      };
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    // startDemoAction
    builder.addCase(startDemoAction.pending, (state) => {
      state.loadingState = { kind: 'loading' };
    });
    builder.addCase(startDemoAction.rejected, (state, { payload }) => {
      state.loadingState = { kind: 'error', error: payload! };
      // if (payload?.statusCode === 409) {
      //   state.demoRunning = true;
      // }
    });
    builder.addCase(startDemoAction.fulfilled, (state, { payload }) => {
      state.loadingState = { kind: 'done' };
      state.demoRunning = { kind: 'some', value: true };
      state.demoId = payload.id;
    });

    // getPresentationAction
    builder.addCase(getPresentationAction.pending, (state) => {
      state.loadingState = { kind: 'loading' };
    });
    builder.addCase(getPresentationAction.rejected, (state, { payload }) => {
      state.loadingState = { kind: 'error', error: payload! };
    });
    builder.addCase(getPresentationAction.fulfilled, (state, { payload }) => {
      state.loadingState = { kind: 'done' };

      state.connectionCode = payload.code;

      const slideOrder = sortByOrder(payload.slides).map((slide) => slide.id);
      state.presentation = { instance: payload, slideOrder };
    });

    // setDemoSlide
    builder.addCase(setDemoSlideAction.pending, (state) => {
      state.loadingState = { kind: 'loading' };
    });
    builder.addCase(setDemoSlideAction.rejected, (state, { payload }) => {
      state.loadingState = { kind: 'error', error: payload! };
    });
    builder.addCase(setDemoSlideAction.fulfilled, (state) => {
      state.loadingState = { kind: 'done' };
    });

    // getSlideResults
    builder.addCase(getSlideResultsAction.pending, (state) => {
      state.loadingState = { kind: 'loading' };
    });
    builder.addCase(getSlideResultsAction.rejected, (state, { payload }) => {
      state.loadingState = { kind: 'error', error: payload! };
    });
    builder.addCase(getSlideResultsAction.fulfilled, (state, { payload }) => {
      state.loadingState = { kind: 'done' };
      if ('choices' in payload) {
        state.votes[payload.slide.id] = {
          respondents: payload.respondents,
          results: payload.choices,
        };
      } else {
        state.answers[payload.slide.id] = {
          respondents: payload.respondents,
          results: payload.answers,
        };
      }
    });

    // getSlideVotingStatus
    builder.addCase(getSlideVotingStatusAction.pending, (state) => {
      state.loadingState = { kind: 'loading' };
    });
    builder.addCase(
      getSlideVotingStatusAction.rejected,
      (state, { payload }) => {
        state.loadingState = { kind: 'error', error: payload! };
      }
    );
    builder.addCase(
      getSlideVotingStatusAction.fulfilled,
      (state, { payload }) => {
        state.loadingState = { kind: 'done' };
        state.votingStatus[payload.slideId] = payload.enableVoting;
      }
    );

    // getCurrentDemoSlide
    builder.addCase(getCurrentDemoSlideAction.pending, (state) => {
      state.loadingState = { kind: 'loading' };
    });
    builder.addCase(
      getCurrentDemoSlideAction.rejected,
      (state, { payload }) => {
        state.loadingState = { kind: 'error', error: payload! };
      }
    );
    builder.addCase(
      getCurrentDemoSlideAction.fulfilled,
      (state, { payload }) => {
        state.loadingState = { kind: 'done' };
        state.currentSlide = { kind: 'some', id: payload.slideId };
      }
    );

    // setSlideVotingStatus
    builder.addCase(setSlideVotingStatusAction.pending, (state) => {
      state.loadingState = { kind: 'loading' };
    });
    builder.addCase(
      setSlideVotingStatusAction.rejected,
      (state, { payload }) => {
        state.loadingState = { kind: 'error', error: payload! };
      }
    );
    builder.addCase(
      setSlideVotingStatusAction.fulfilled,
      (state, { payload }) => {
        state.loadingState = { kind: 'done' };
        state.votingStatus[payload.slideId] = payload.enableVoting;
      }
    );

    // getPresentationDemos
    builder.addCase(getPresentationDemosAction.pending, (state) => {
      state.loadingState = { kind: 'loading' };
    });
    builder.addCase(
      getPresentationDemosAction.rejected,
      (state, { payload }) => {
        state.loadingState = { kind: 'error', error: payload! };
      }
    );
    builder.addCase(
      getPresentationDemosAction.fulfilled,
      (state, { payload }) => {
        state.loadingState = { kind: 'done' };

        const liveDemo = payload.demos.find((demo) => demo.live);
        if (liveDemo) {
          state.demoId = liveDemo.id;
          state.demoRunning = { kind: 'some', value: true };
        } else {
          state.demoRunning = { kind: 'some', value: false };
        }
      }
    );
  },
});

export const {
  setCurrentSlide,
  startConnectionCreator,
  clearState,
  setDemoUsersCount,
  setSlideResultsVotes,
  setVotingVisibility,
  setSlideResultsAnswers,
  // toggleVoting
} = creatorDemoSessionSlice.actions;

export default creatorDemoSessionSlice.reducer;
