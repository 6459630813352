import { createSlice } from '@reduxjs/toolkit';
import { getBarChartDataAction } from 'store/barChart/thunk';
import { BAR_CHART_ALIAS, IBarChartStore } from 'store/barChart/types';

const initialState: IBarChartStore = {
  loading: false,
  error: null,
  dataSource: [],
  categories: [],
};

export const barChartSlice = createSlice({
  name: BAR_CHART_ALIAS,
  initialState,
  reducers: {
    clearBarChartData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getBarChartDataAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBarChartDataAction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    builder.addCase(getBarChartDataAction.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.dataSource = payload.data;
      state.categories = payload.categories;
    });
  },
});

export const { clearBarChartData } = barChartSlice.actions;

export default barChartSlice.reducer;
