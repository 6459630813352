import { createSlice } from '@reduxjs/toolkit';
import { GALLERY_ALIAS, IGalleryInitialState } from './types';
import {
  customAxiosUploadFile,
  deleteImage,
  getAllImages,
  getImage,
  getImageArr,
} from './thunk';

const initialState: IGalleryInitialState = {
  loading: false,
  error: null,
  uploadImage: '',
  imageUrl: '',
  allImages: [],
  deleteImage: null,
  currentSlideID: null,
  uploadImageState: null,
  imgPresentation: [],
};

export const gallerySlice = createSlice({
  name: GALLERY_ALIAS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(customAxiosUploadFile.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(customAxiosUploadFile.rejected, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(customAxiosUploadFile.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.uploadImage = payload.data;
    });
    builder.addCase(getAllImages.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllImages.rejected, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllImages.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.allImages = payload.data;
    });
    builder.addCase(getImage.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getImage.rejected, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getImage.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.imageUrl = payload.data.url;
    });
    builder.addCase(getImageArr.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getImageArr.rejected, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getImageArr.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.imgPresentation[payload.id[0]] = payload.data.url || null;
    });
    builder.addCase(deleteImage.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteImage.rejected, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteImage.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.deleteImage = payload;
    });
  },
});
export default gallerySlice.reducer;
