import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';
import { IResponseError } from './types';

export interface IDeleteSlideRequest {
  slideId: number;
}

export type TDeleteSlideResponse = {} | IResponseError;

export const deleteSlideRequest = (
  requestParams: IDeleteSlideRequest
): TResponse<TDeleteSlideResponse> => {
  return makeRequest({
    url: `/slide/${requestParams.slideId}`, // baseUrl подставиться сам,
    method: 'DELETE',
  });
};
