import makeRequest from 'network/instance/makeRequest';
import { IResponseError } from 'network/rest/commonTypes';
import { TResponse } from 'network/instance/types';

export interface IStartDemoRequest {
  presentationId: number;
  slideId: number; // slide to start from?
}

export interface IStartDemoResponseOk {
  id: number;
  presentation: {
    id: number;
    author: {
      id: number;
    };
    code: number;
  };
  live: boolean;
  created_at: string;
  updated_at: string;
}

export type TStartDemoResponse = IStartDemoResponseOk | IResponseError;

export const startDemo = (
  params: IStartDemoRequest
): TResponse<TStartDemoResponse> => {
  return makeRequest({
    url: `/presentation/${params.presentationId}/start`,
    method: 'PUT',
    data: {
      slideId: params.slideId,
    },
  });
};
