import { AxiosResponse } from 'axios';

export type TResponse<T> = Promise<AxiosResponse<T>>;

export enum EEnvironment {
  Stage = 'stage',
  Production = 'main',
  Development = 'development',
}

export const baseHostName =
  process.env.REACT_APP_API_BASE_HOST_NAME || 'ws://localhost:8000';

export const Stage = process.env.REACT_APP_API_STAGE || 'http://localhost:8000';

export const Production =
  process.env.REACT_APP_API_PRODUCTION || 'http://localhost:8000';

export const Development =
  process.env.REACT_APP_API_DEVELOPMENT || 'http://localhost:8000';

// export const baseHostName = 'ws://localhost:8000';

// export const Stage = 'http://localhost:8000';

// export const Production = 'http://localhost:8000';

// export const Development = 'http://localhost:8000';
