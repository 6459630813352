import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';
import { IResponseError, ISlide } from './types';

export interface IGetSlidesRequest {
  id: string;
}

export interface IGetSlidesResponseOk {
  statusCode?: number;
  id: number;
  name: string;
  code: number;
  theme: string;
  author: {
    id: number;
  };
  slides: ISlide[];
  created_at: string;
  updated_at: string;
}

export type TGetSlidesResponse = IGetSlidesResponseOk | IResponseError;

export const getSlidesRequest = (
  requestParams: IGetSlidesRequest
): TResponse<TGetSlidesResponse> => {
  return makeRequest({
    url: `/presentation/${requestParams.id}`, // baseUrl подставиться сам,
    method: 'GET',
  });
};
