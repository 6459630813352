import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Skeleton } from 'antd';

import {
  ForgetPasswordPage,
  ChangePasswordPage,
  AgainPasswordPage,
  LogInPage,
  MainPage,
  Page404,
  PolicyPage,
  PresentationEditPage,
  SignUpPage,
  StartPage,
  UserSessionViewer,
  CreatorSessionViewer,
  LandingPage,
  TermsUse,
} from 'containers/Router';

import MainPageLayout from 'containers/layouts/MainPageLayout';
import AuthRequired from './entities/AuthRequired';

import { ROUTES } from './constants/routes';

import './App.css';
import './styles/global.scss';

const App = () => {
  return (
    <Suspense fallback={<Skeleton active />}>
      <Routes>
        <Route path={ROUTES.home.path} element={<LandingPage />} />
        <Route path={ROUTES.signup.path} element={<SignUpPage />} />
        <Route path={ROUTES.login.path} element={<LogInPage />} />
        <Route
          path={ROUTES.forget_password.path}
          element={<ForgetPasswordPage />}
        />
        <Route
          path={ROUTES.again_password.path}
          element={<AgainPasswordPage />}
        />
        <Route
          path={ROUTES.change_password.path}
          element={<ChangePasswordPage />}
        />
        {/* <Route path={ROUTES.home.path} element={<StartPage />} /> */}
        <Route element={<MainPageLayout />}>
          <Route
            path={ROUTES.presentations.path}
            element={
              <AuthRequired>
                <MainPage />
              </AuthRequired>
            }
          />
          <Route
            path={ROUTES.reports.path}
            element={
              <AuthRequired>
                <div>Отчеты в разработке</div>
              </AuthRequired>
            }
          />
          <Route
            path={ROUTES.templates.path}
            element={
              <AuthRequired>
                <div>Шаблоны в разработке</div>
              </AuthRequired>
            }
          />
          <Route
            path={ROUTES.basket.path}
            element={
              <AuthRequired>
                <div>Корзина в разработке</div>
              </AuthRequired>
            }
          />
          <Route
            path={ROUTES.helps.path}
            element={
              <AuthRequired>
                <div>Помощь в разработке</div>
              </AuthRequired>
            }
          />
          <Route
            path={ROUTES.settings.path}
            element={
              <AuthRequired>
                <div>Настройки в разработке</div>
              </AuthRequired>
            }
          />
        </Route>
        <Route path={ROUTES.policy.path} element={<PolicyPage />} />
        <Route path={ROUTES.terms_use.path} element={<TermsUse />} />
        <Route
          path={ROUTES.presentations.route}
          element={
            <AuthRequired>
              <PresentationEditPage />
            </AuthRequired>
          }
        />
        <Route path={ROUTES.participate.path} element={<UserSessionViewer />} />
        <Route
          path={ROUTES.presentations_demo.route}
          element={
            <AuthRequired>
              <CreatorSessionViewer />
            </AuthRequired>
          }
        />
        <Route path={ROUTES.notFound.path} element={<Page404 />} />
      </Routes>
    </Suspense>
  );
};

export default App;
