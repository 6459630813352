import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';
import { IChoice, IResponseError } from './types';

export interface IAddChoiceRequest {
  slideId: number;
  data: {
    order: number;
    label: string;
    image?: string;
    correctAnswer?: boolean;
  };
}

export type TAddChoiceResponse = IChoice | IResponseError;

export const addChoiceRequest = ({
  slideId,
  data,
}: IAddChoiceRequest): TResponse<TAddChoiceResponse> => {
  return makeRequest({
    url: `/slide/${slideId}/choice`, // baseUrl подставиться сам,
    method: 'POST',
    data,
  });
};
