import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetPresentationsResponseOK } from 'network/rest/presentations/getPresentationsList';
import { IPresentation } from 'network/rest/presentations/reusableTypes';
import {
  createPresentationAction,
  deletePresentationAction,
  fetchPresentationsAction,
  updatePresentationAction,
} from './thunk';
import {
  IPresentationsList,
  IPresentationSlice,
  PRESENTATIONS_ALIAS,
} from './types';

const initialState: IPresentationSlice = {
  loading: false,
  error: null,
  response: null,
  presentationName: '',
  presentationsList: [],
  presentation: null,
};

const presentationSlice = createSlice({
  name: PRESENTATIONS_ALIAS,
  initialState,
  reducers: {
    setPresentationName(state, action: PayloadAction<string>) {
      state.presentationName = action.payload.trim();
    },
    setPresentatiosList(state, action: PayloadAction<IPresentationsList[]>) {
      state.presentationsList = action.payload;
    },
    clearPresentation(state) {
      state.presentation = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPresentationsAction.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(
      fetchPresentationsAction.fulfilled,
      (state, { payload }) => {
        state.presentationsList = payload as IGetPresentationsResponseOK[];
        state.loading = false;
      }
    );
    builder.addCase(fetchPresentationsAction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });

    builder.addCase(createPresentationAction.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(
      createPresentationAction.fulfilled,
      (state, { payload }) => {
        state.presentation = payload as IPresentation;
        state.loading = false;
      }
    );
    builder.addCase(createPresentationAction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });

    builder.addCase(updatePresentationAction.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(updatePresentationAction.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updatePresentationAction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });

    builder.addCase(deletePresentationAction.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.response = null;
    });
    builder.addCase(deletePresentationAction.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePresentationAction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
  },
});

export const { setPresentationName, clearPresentation } =
  presentationSlice.actions;

export default presentationSlice.reducer;
