import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';
import { IResponseError, ISlide } from './types';

export interface IDuplicateSlideRequest {
  sourceSlideId: number;
  data: {
    order: number;
    type?: string;
    heading?: string;
    description?: string;
    content?: {};
    enableVoting?: boolean;
    showResults?: boolean;
    choices?: [];
    range?: {
      min: number;
      max: number;
      labels: string[];
    };
    layout?: string;
    image?: string;
  };
}

export type TDuplicateSlideResponse = ISlide | IResponseError;

export const duplicateSlideRequest = (
  requestData: IDuplicateSlideRequest
): TResponse<TDuplicateSlideResponse> => {
  return makeRequest({
    url: `/slide/${requestData.sourceSlideId}/copy`, // baseUrl подставиться сам,
    method: 'POST',
    data: requestData.data,
  });
};
