import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';
import { IPresentation, IResponseError } from './reusableTypes';

export interface ICreatePresentationRequest {
  id?: number;
  name: string;
}

export type TCreatePresentationResponse = IPresentation | IResponseError;

export const createPresentationRequest = (
  data: ICreatePresentationRequest
): TResponse<TCreatePresentationResponse> => {
  return makeRequest({
    method: 'POST',
    url: '/presentation',
    data,
  });
};
