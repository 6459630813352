/* eslint-disable react/state-in-constructor */
/* eslint-disable react/destructuring-assignment */

import SubmitButton from 'components/SubmitButton';
import { ROUTES } from 'constants/routes';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import styles from './index.module.scss';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      const handleRedirect = () => {
        window.location.href = ROUTES.home.path;
      };
      return (
        <div className={styles.container}>
          <h1>Что-то пошло не так...</h1>
          <SubmitButton
            className={styles.button_back}
            name="Вернуться на главную"
            onClick={handleRedirect}
            isActive
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
