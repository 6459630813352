import { RootState } from 'store';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getUserInfoRequest,
  IGetUserInfoOK,
  IResponseError,
  TUserInfoResponse,
} from 'network/rest/user/getUserInfo';

import { getUserID } from 'store/auth/selectors';
import { USER_ALIAS } from './types';

export const fetchUserInfoAction = createAsyncThunk<
  TUserInfoResponse,
  {},
  {
    rejectValue: IResponseError;
  }
>(
  `${USER_ALIAS}/fetchUserInfo`,
  async (_payload, { rejectWithValue, getState }) => {
    try {
      const id = getUserID(getState() as RootState);
      const response = await getUserInfoRequest({ id });
      const data = response.data as IGetUserInfoOK;
      if (response.status === 200) {
        return data;
      }
      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  }
);
