import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';
import { IResponseError } from './reusableTypes';

export interface IDeletePresentationResponseOK {
  statusCode: number;
}

export interface IDeletePresentationRequest {
  id: number;
}

export type TDeletePresentationResponse = {} | IResponseError;

export const deletePresentationRequest = (
  id: number
): TResponse<TDeletePresentationResponse> => {
  return makeRequest({
    method: 'DELETE',
    url: `/presentation/${id}`,
  });
};
