import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

interface ITextButton extends HTMLAttributes<HTMLButtonElement> {
  text: string;
  size?: 'regular' | 'small';
  danger?: boolean;
}

const TextButton = (props: ITextButton) => {
  const { text, size, danger, ...rest } = props;

  return (
    <button
      {...rest}
      type="button"
      className={classNames(styles.button, {
        [styles.button__small]: size === 'small',
      })}
    >
      <p
        className={classNames(styles.button__text, {
          [styles.button__danger]: danger,
        })}
      >
        {text}
      </p>
    </button>
  );
};

export default TextButton;
