import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAddChoiceRequest } from 'network/rest/presentationEdit/addChoice';
import { IAddSlideRequest } from 'network/rest/presentationEdit/addSlide';
import { IDeleteChoiceRequest } from 'network/rest/presentationEdit/deleteChoice';
import { IDeleteSlideRequest } from 'network/rest/presentationEdit/deleteSlide';
import { IDuplicateSlideRequest } from 'network/rest/presentationEdit/duplicateSlide';
import { IUpdateChoiceRequest } from 'network/rest/presentationEdit/updateChoice';
import { IUpdateSlideRequest } from 'network/rest/presentationEdit/updateSlide';
import { ISlide } from 'network/rest/presentationEdit/types';
import {
  addChoiceAction,
  addSlideAction,
  deleteChoiceAction,
  deleteSlideAction,
  duplicateSlideAction,
  getSlidesAction,
  updateChoiceAction,
  updateSlideAction,
} from './thunk';

import { EDIT_ALIAS, IPresentationEditState } from './types';

const initialState: IPresentationEditState = {
  loading: false,
  error: null,
  response: null,

  savingLoading: false,
  savingStatus: null,
  savingAttempts: 0,
  addStack: [],
  isSlideActionActive: false,
  duplicateStack: [],
  updatingStack: [],
  deleteStack: [],
  addChoiceStack: [],
  isChoiceActionActive: false,
  updatingChoiceStack: [],
  deleteChoiceStack: [],

  activeSlideNumber: 1,
  dragedSlideId: null,
};

export const editSlice = createSlice({
  name: EDIT_ALIAS,
  initialState,
  reducers: {
    clearEditData: () => initialState,
    clearEditError: (state) => {
      state.error = null;
      state.savingAttempts = 0;
    },
    clearStacks: (state) => {
      state.addStack = [];
      state.duplicateStack = [];
      state.updatingStack = [];
      state.deleteStack = [];
      state.addChoiceStack = [];
      state.updatingChoiceStack = [];
      state.deleteChoiceStack = [];
      state.savingStatus = 'needUpdate';
    },
    addSlide: (state, action: PayloadAction<IAddSlideRequest>) => {
      state.addStack = [...state.addStack, action.payload];
      state.isSlideActionActive = true;
    },
    duplicateSlide: (state, action: PayloadAction<IDuplicateSlideRequest>) => {
      state.duplicateStack = [...state.duplicateStack, action.payload];
      state.isSlideActionActive = true;
    },
    deleteSlide: (state, action: PayloadAction<IDeleteSlideRequest>) => {
      state.deleteStack = [...state.deleteStack, action.payload];
      state.isSlideActionActive = true;
    },
    updateSlide: (state, action: PayloadAction<IUpdateSlideRequest>) => {
      state.updatingStack = [...state.updatingStack, action.payload];
    },

    addChoice: (state, action: PayloadAction<IAddChoiceRequest>) => {
      state.addChoiceStack = [...state.addChoiceStack, action.payload];
      state.isChoiceActionActive = true;
    },
    updateChoice: (state, action: PayloadAction<IUpdateChoiceRequest>) => {
      state.updatingChoiceStack = [
        ...state.updatingChoiceStack,
        action.payload,
      ];
    },
    deleteChoice: (state, action: PayloadAction<IDeleteChoiceRequest>) => {
      state.deleteChoiceStack = [...state.deleteChoiceStack, action.payload];
      state.isChoiceActionActive = true;
    },
    emptyTast: (state, action) => {
      // empty
    },
    setActiveSlideNumber: (state, action: PayloadAction<number>) => {
      state.activeSlideNumber = action.payload;
    },
    setDragedSlideId: (state, action: PayloadAction<null | number>) => {
      state.dragedSlideId = action.payload;
    },
    changeSlides: (state, action: PayloadAction<ISlide[]>) => {
      state.response!.slides = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSlidesAction.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSlidesAction.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    builder.addCase(getSlidesAction.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.savingStatus = null;
      state.response = payload;
      state.savingLoading = false;
    });
    builder.addCase(addSlideAction.pending, (state) => {
      state.savingLoading = true;
      state.error = null;
      state.savingStatus = null;
    });
    builder.addCase(addSlideAction.rejected, (state, { payload }) => {
      state.error = payload || null;
      state.savingAttempts += 1;
    });
    builder.addCase(addSlideAction.fulfilled, (state, { payload }) => {
      state.savingLoading = false;
      state.savingAttempts = 0;
      state.addStack = state.addStack.slice(1);
      // state.savingStatus = 'needUpdate';
      state.isSlideActionActive = false;
      state.response!.slides = [...state.response!.slides, payload];
      state.activeSlideNumber += 1;
    });
    builder.addCase(duplicateSlideAction.pending, (state) => {
      state.savingLoading = true;
      state.error = null;
      state.savingStatus = null;
    });
    builder.addCase(duplicateSlideAction.rejected, (state, { payload }) => {
      state.error = payload || null;
      state.savingAttempts += 1;
    });
    builder.addCase(duplicateSlideAction.fulfilled, (state, { payload }) => {
      state.savingLoading = false;
      state.savingAttempts = 0;
      state.duplicateStack = state.duplicateStack.slice(1);
      // state.savingStatus = 'needUpdate';
      state.isSlideActionActive = false;
      state.response!.slides = [...state.response!.slides, payload];
      state.activeSlideNumber += 1;
    });
    builder.addCase(updateSlideAction.pending, (state) => {
      state.savingLoading = true;
      state.error = null;
      state.savingStatus = null;
    });
    builder.addCase(updateSlideAction.rejected, (state, { payload }) => {
      state.error = payload || null;
      state.savingAttempts += 1;
    });
    builder.addCase(updateSlideAction.fulfilled, (state, { payload }) => {
      state.savingLoading = false;
      state.savingAttempts = 0;
      state.updatingStack = state.updatingStack.slice(1);
      // state.savingStatus = 'needUpdate';
      state.response!.slides = payload;
    });
    builder.addCase(deleteSlideAction.pending, (state) => {
      state.savingLoading = true;
      state.error = null;
    });
    builder.addCase(deleteSlideAction.rejected, (state, { payload }) => {
      state.error = payload || null;
      state.savingAttempts += 1;
    });
    builder.addCase(deleteSlideAction.fulfilled, (state, { payload }) => {
      state.savingLoading = false;
      state.savingAttempts = 0;
      state.deleteStack = state.deleteStack.slice(1);
      // state.savingStatus = 'needUpdate';
      state.isSlideActionActive = false;
    });
    builder.addCase(addChoiceAction.pending, (state) => {
      state.savingLoading = true;
      state.error = null;
      state.savingStatus = null;
    });
    builder.addCase(addChoiceAction.rejected, (state, { payload }) => {
      state.error = payload || null;
      state.savingAttempts += 1;
    });
    builder.addCase(addChoiceAction.fulfilled, (state, { payload }) => {
      state.savingLoading = false;
      state.savingAttempts = 0;
      state.addChoiceStack = state.addChoiceStack.slice(1);
      // state.savingStatus = 'needUpdate';
      state.isChoiceActionActive = false;
      state.response!.slides = payload;
    });
    builder.addCase(updateChoiceAction.pending, (state) => {
      state.savingLoading = true;
      state.error = null;
      state.savingStatus = null;
    });
    builder.addCase(updateChoiceAction.rejected, (state, { payload }) => {
      state.error = payload || null;
      state.savingAttempts += 1;
    });
    builder.addCase(updateChoiceAction.fulfilled, (state, { payload }) => {
      state.savingLoading = false;
      state.savingAttempts = 0;
      state.updatingChoiceStack = state.updatingChoiceStack.slice(1);
      // state.savingStatus = 'needUpdate';
      state.response!.slides = payload;
    });
    builder.addCase(deleteChoiceAction.pending, (state) => {
      state.savingLoading = true;
      state.error = null;
    });
    builder.addCase(deleteChoiceAction.rejected, (state, { payload }) => {
      state.error = payload || null;
      state.savingAttempts += 1;
    });
    builder.addCase(deleteChoiceAction.fulfilled, (state, { payload }) => {
      state.savingLoading = false;
      state.savingAttempts = 0;
      state.deleteChoiceStack = state.deleteChoiceStack.slice(1);
      // state.savingStatus = 'needUpdate';
      state.isChoiceActionActive = false;
    });
  },
});

export const {
  clearEditData,
  clearEditError,
  clearStacks,
  addSlide,
  duplicateSlide,
  deleteSlide,
  updateSlide,
  addChoice,
  updateChoice,
  deleteChoice,
  setActiveSlideNumber,
  setDragedSlideId,
  emptyTast,
  changeSlides,
} = editSlice.actions;

export default editSlice.reducer;
