import { TCreatePresentationResponse } from 'network/rest/presentations/createPresentation';
import { TGetPresentationsResponse } from 'network/rest/presentations/getPresentationsList';
import { IPresentation } from 'network/rest/presentations/reusableTypes';

export const PRESENTATIONS_ALIAS = 'presentationsAlias' as const;

export interface IPresentationsList {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface IPresentationSlice {
  loading: boolean;
  error: IRequestError | null;
  response: TGetPresentationsResponse | TCreatePresentationResponse | null;
  presentationName: string;
  presentationsList: IPresentationsList[] | [];
  presentation: IPresentation | null;
}

export interface IRequestError {
  statusCode: number;
  error: string;
  message: string;
}
