import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getUserState } from 'store/user/selectors';
import { logoutAction } from 'store/auth/thunk';
import { fetchUserInfoAction } from 'store/user/thunk';
import { getUserID } from 'store/auth/selectors';
import TextButton from 'components/TextButton';
import styles from './index.module.scss';

import { ROUTES } from '../../constants/routes';

import { ReactComponent as UserPic } from './svg/userphoto2.svg';
import { ReactComponent as UserArrow } from './svg/arrow.svg';

import { ReactComponent as SettingsIcon } from './svg/settings.svg';
import { ReactComponent as ExitIcon } from './svg/exit.svg';

interface IUserProfile {
  additionalClass?: string;
  showArrow?: boolean;
  showInfo?: boolean;
  showPic?: boolean;
  flipped?: boolean;
  small?: boolean;
  logout?: boolean;
}

const UserProfile = (props: IUserProfile) => {
  const {
    additionalClass,
    showArrow = true,
    showInfo = true,
    showPic = true,
    flipped,
    small,
    logout = false,
  } = props;

  const dispatch = useAppDispatch();
  const { name, email } = useAppSelector(getUserState);
  const userID = useAppSelector(getUserID);
  const navigate = useNavigate();

  useEffect(() => {
    if (userID) {
      dispatch(fetchUserInfoAction({}));
    }
  }, [userID]);

  return (
    <div
      className={classNames(styles.user_profile, {
        [styles.user_profile__small]: small,
      })}
    >
      <div
        className={classNames(styles.user_profile__info__container, {
          [styles.user_profile__flipped]: flipped,
        })}
      >
        {showArrow && (
          <div className={styles.user_profile__arrow}>
            <UserArrow />
          </div>
        )}
        {showInfo && (
          <div className={styles.user_profile__info}>
            <p className={styles.user_profile__name}>{name}</p>
            <p className={styles.user_profile__email}>{email}</p>
          </div>
        )}
        {showPic && (
          <div className={styles.user_profile__pic}>
            <UserPic />
          </div>
        )}
      </div>
      {logout && (
        <div>
          <div className={styles.user_profile__exit}>
            <div className={styles.user_profile_buttons}>
              {/* <div className={styles.user_profile_btn}>
                <SettingsIcon />
                <TextButton
                  className={styles.user_profile_settings}
                  text="Настройки"
                  onClick={() => {
                    navigate(ROUTES.settings.path);
                  }}
                  danger
                  size="small"
                />
              </div> */}
              <div className={styles.user_profile_btn}>
                <ExitIcon />
                <TextButton
                  className={classNames(styles.logout_button, additionalClass)}
                  text="Выйти"
                  onClick={() => {
                    dispatch(logoutAction({}));
                    navigate(ROUTES.home.path);
                  }}
                  danger
                  size="small"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
