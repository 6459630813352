import React from 'react';
import classNames from 'classnames';

import { Spin } from 'antd';

import styles from './index.module.scss';

interface ISubmitButton {
  name: string;
  onClick: () => void | undefined;
  className?: string;
  isActive: boolean;
  isLoading?: boolean;
}

const SubmitButton = (props: ISubmitButton) => {
  const {
    name,
    onClick,
    className,
    isActive = false,
    isLoading = false,
  } = props;

  const newStyle = classNames(
    styles.button,
    {
      [styles.active]: isActive,
      [styles.inactive]: !isActive,
    },
    className
  );
  return (
    <button
      type="submit"
      onClick={isActive ? onClick : undefined}
      className={newStyle}
    >
      {isLoading ? <Spin /> : name}
    </button>
  );
};

export default SubmitButton;

