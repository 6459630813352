import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';
import { IResponseError, ISlide } from './types';

export interface IAddSlideRequest {
  presentation: {
    id: number;
  };
  order: number;
  type: string;
  heading?: string;
  description?: string;
  content?: {};
  layout?: string;
  image?: string;
}

export type TAddSlideResponse = ISlide | IResponseError;

export const addSlideRequest = (
  data: IAddSlideRequest
): TResponse<TAddSlideResponse> => {
  return makeRequest({
    url: '/slide', // baseUrl подставиться сам,
    method: 'POST',
    data,
  });
};
