import {
  IGetSlideResultsAnswers,
  IGetSlideResultsResponseOk,
} from 'network/rest/creatorDemoSession/getSlideResults';
import { TLoadingState } from 'store/commonTypes';
import { IImage } from '../../network/rest/presentationEdit/types';

export interface IChoice {
  id: number;
  label: string;
}

export interface IMultipleChoiceSlide {
  kind: 'multipleChoice';
  title: string;
  choices: IChoice[];
  image: IImage[];
  selectionType?: boolean;
}

export interface IBound {
  value: number;
  label: string;
}

export interface IScale {
  id: number;
  label: string;
}

export interface IScaleSlide {
  kind: 'scale';
  title: string;
  scales: IScale[];
  min: IBound;
  max: IBound;
}

export interface IParagraphSlide {
  kind: 'paragraph';
  title: string;
  text: string;
  layout: 'left' | 'center';
  image: IImage[];
}

export interface IOpenQuestionSlide {
  kind: 'open-answer';
  heading: string;
  layout: 'left' | 'center';
}

export interface IWordCloudSlide {
  kind: 'word-cloud';
  heading: string;
  layout: 'left' | 'center';
}

export type TSlide =
  | IParagraphSlide
  | IMultipleChoiceSlide
  | IScaleSlide
  | IOpenQuestionSlide
  | IWordCloudSlide
  | { kind: 'empty' };

export enum EStatus {
  Idle = 'idle', // также для статических слайдов
  JustVoted = 'just_voted',
  AlreadyVoted = 'already_voted',
  VotingClosed = 'voting_closed',
  NoDemonstration = 'no_demonstration',
}

export type TPresentation = { [id: number]: TSlide };

export type TWebsocketStatus =
  | { kind: 'noConnection' }
  | { kind: 'connecting' }
  | { kind: 'connected' };

export type TVoteValues = { [choiceId: number]: number };

export interface IFlatVote {
  choiceId: number;
  value: number;
}

export interface IAnswerVote {
  answer: string;
}

export interface IUserDemoSessionState {
  respondentKey: string | null;
  code?: number;
  websocketStatus: TWebsocketStatus;
  loadingState: TLoadingState;
  demonstrationStatus: EStatus;
  presentation?: TPresentation;
  currentSlideId?: number;
  answeredSlideIds: number[];
  currentVote: TVoteValues;
}

export enum SlideType {
  EMPTY = 'empty',
  BIG = 'big',
  PARAGRAPH = 'paragraph',
  HEADING = 'heading',
  SELECT_ANSWER = 'select-answer',
  CHOICES = 'choices',
  SCALES = 'scales',
  OPEN = 'open-answer',
  CLOUD = 'word-cloud',
}

export enum SlideLayout {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export type RangeType = {
  min: number;
  max: number;
  labels: string[];
};

export interface ISlide {
  id: number;
  presentation: IPresentation;
  order: number;
  type: SlideType;
  layout: SlideLayout;
  image: string; // Gallery;
  content?: object | null;
  heading: string;
  description: string;
  enableVoting: boolean;
  showResults: boolean;
  choices?: IChoiceFull[];
  range: RangeType;
  created_at: Date;
  updated_at: Date;
  isLive: boolean;
  selectionType?: boolean;
}

export interface IChoiceFull {
  id: number;
  slide: ISlide;
  order: number;
  label: string;
  image?: string;
  correctAnswer?: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface IUser {
  id: number;
  email: string;
  password: string;
  resetToken: string;
  resetTokenExp: Date;
  name: string;
  respondentKey: string;
  role: IUserRole;
  created_at: Date;
  updated_at: Date;
  isAdmin: boolean;
  isOwner: (entity: unknown) => Promise<boolean>;
  canEdit: (entity: unknown) => Promise<boolean>;
}

export enum UserRoleID {
  ADMIN = 1,
  USER = 2,
}

export interface IUserRole {
  id: UserRoleID;
  name: 'admin' | 'user' | string;
}

export interface IPresentation {
  id: number;
  name: string;
  author: IUser;
  code: number;
  theme: string;
  slides: ISlide[];
  created_at: Date;
  updated_at: Date;
  isLive: boolean;
}

export type PaceDTO = {
  demonstration: { id?: number; live: boolean };
  presentation: Pick<IPresentation, 'id' | 'code'>;
  slide: Pick<ISlide, 'id' | 'enableVoting'>;
  results: IGetSlideResultsResponseOk | IGetSlideResultsAnswers;
  usersCount: number;
};

export const USER_DEMO_SESSION_ALIAS = 'userDemoSessionAlias';
