type TRouteNames =
  | 'login'
  | 'home'
  | 'forget_password'
  | 'again_password'
  | 'change_password'
  | 'presentations'
  | 'notFound'
  | 'signup'
  | 'reports'
  | 'templates'
  | 'basket'
  | 'helps'
  | 'settings'
  | 'policy'
  | 'terms_use'
  | 'presentations_demo'
  | 'participate';

interface IRoute {
  path: string;
  route: string;
  name: string;
}

export const ROUTES: Record<TRouteNames, IRoute> = {
  signup: {
    path: '/signup',
    route: '/signup',
    name: 'Регистрация',
  },
  login: {
    path: '/login',
    route: '/login',
    name: 'Авторизация',
  },
  forget_password: {
    path: '/forget_password',
    route: '/forget_password',
    name: 'Забыли пароль',
  },
  again_password: {
    path: '/again_password',
    route: '/again_password',
    name: 'Отправить инструкцию',
  },
  change_password: {
    path: '/change_password/:userId/:resetToken',
    route: '/change_password',
    name: 'Восстановить пароль',
  },
  home: {
    path: '/',
    route: '/',
    name: 'Главная',
  },
  presentations: {
    path: '/presentations',
    route: '/presentations/:presentationId',
    name: 'Презентации',
  },
  presentations_demo: {
    path: '/presentations',
    route: '/presentations/:presentationId/demo',
    name: 'Презентации',
  },
  participate: {
    path: '/participate',
    route: '/participate',
    name: 'Участие',
  },
  reports: {
    path: '/reports',
    route: '/reports',
    name: 'Отчеты',
  },
  templates: {
    path: '/templates',
    route: '/templates',
    name: 'Шаблоны',
  },
  basket: {
    path: '/basket',
    route: '/basket',
    name: 'Корзина',
  },
  helps: {
    path: '/helps',
    route: '/helps',
    name: 'Помощь',
  },
  settings: {
    path: '/settings',
    route: '/settings',
    name: 'Настройки',
  },
  policy: {
    path: '/policy',
    route: '/policy',
    name: 'Политика',
  },
  terms_use: {
    path: '/terms_use',
    route: '/terms_use',
    name: 'Условия использования'
  },
  notFound: {
    path: '*',
    route: '*',
    name: '404',
  },
};

export function getSlideResultsRoute(slideId: number) {
  return `/slide/${slideId}/results`;
};
